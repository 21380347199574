'use client';

import { css } from 'styled-components';
import { Property } from 'csstype';
import { mediaLgMin, mediaMdMax, mediaMdMin, mediaSmMax, mediaSmMin } from '../utils/breakpoints';
import { NestedThemePaths, resolveThemeValue } from '../design/utils';

export type ColorString = NestedThemePaths | `#${string}`;

interface GapProps {
  $gap?: NestedThemePaths | `${number}px`;
  $gapMdMax?: NestedThemePaths | `${number}px`;
}

export interface GapPropsResponsive extends GapProps {
  $mdMin?: GapProps;
  $lgMin?: GapProps;
}

export const gap = css<GapPropsResponsive>`
  gap: ${({ $gap: gapProp }) => resolveThemeValue(gapProp)};

  ${mediaMdMax} {
    /* TODO invert to mobile first, fix where changed, change to mdMin object */
    gap: ${({ $gapMdMax }) => resolveThemeValue($gapMdMax)};
  }

  ${mediaMdMin} {
    gap: ${({ $mdMin }) => resolveThemeValue($mdMin?.$gap)};
  }

  ${mediaLgMin} {
    gap: ${({ $lgMin }) => resolveThemeValue($lgMin?.$gap)};
  }
`;

export interface SizingProps {
  $width?: string;
  $height?: string;
  $minWidth?: string;
  $maxWidth?: string;
  $minHeight?: string;
  $maxHeight?: string;
}

export interface MarginProps {
  $margin?: string | 'auto';
  $mx?: NestedThemePaths | `${number}px` | 'auto';
  $my?: NestedThemePaths | `${number}px` | 'auto';
  $mt?: NestedThemePaths | `${number}px` | 'auto';
  $mb?: NestedThemePaths | `${number}px` | 'auto';
  $ml?: NestedThemePaths | `${number}px` | 'auto';
  $mr?: NestedThemePaths | `${number}px` | 'auto';
}

export interface PaddingProps {
  $padding?:
    | NestedThemePaths
    | `${number}px`
    | `${number}px ${number}px`
    | `${number}px ${number}px ${number}px ${number}px`;
  $px?: NestedThemePaths | `${number}px`;
  $py?: NestedThemePaths | `${number}px`;
  $pt?: NestedThemePaths | `${number}px`;
  $pb?: NestedThemePaths | `${number}px`;
  $pl?: NestedThemePaths | `${number}px`;
  $pr?: NestedThemePaths | `${number}px`;
}

export interface BorderProps {
  $border?: Property.Border;
  $borderTop?: Property.BorderTop;
  $borderBottom?: Property.BorderBottom;
  $borderLeft?: Property.BorderLeft;
  $borderRight?: Property.BorderRight;
  $borderRadius?: NestedThemePaths | `${number}px` | `${number}%`;
  $borderColor?: ColorString;
}

export interface BorderPropsResponsive extends BorderProps {
  $mdMin?: BorderProps;
  $mdMax?: BorderProps;
  $lgMin?: BorderProps;
  $smMax?: BorderProps;
}

export interface SizingPropsResponsive extends SizingProps {
  $mdMin?: SizingProps;
  $lgMin?: SizingProps;
  $mdMax?: SizingProps;
  $smMin?: SizingProps;
}

export interface MarginPropsResponsive extends MarginProps {
  $mdMin?: MarginProps;
  $lgMin?: MarginProps;
  $smMax?: MarginProps;
  $mdMax?: MarginProps;
  $smMin?: MarginProps;
}

export interface PaddingPropsResponsive extends PaddingProps {
  $mdMin?: PaddingProps;
  $lgMin?: PaddingProps;
  $smMax?: PaddingProps;
  $mdMax?: PaddingProps;
  $smMin?: PaddingProps;
}

const buildBorderCSS = ({
  $border,
  $borderTop,
  $borderLeft,
  $borderRight,
  $borderBottom,
  $borderRadius,
  $borderColor,
}: BorderProps) => css`
  border: ${$border};
  border-top: ${$borderTop};
  border-bottom: ${$borderBottom};
  border-left: ${$borderLeft};
  border-right: ${$borderRight};
  border-radius: ${resolveThemeValue($borderRadius)};
  border-color: ${resolveThemeValue($borderColor)};
`;

const buildSizingCSS = ({ $width, $height, $minWidth, $maxWidth, $minHeight, $maxHeight }: SizingProps) => css`
  width: ${$width};
  height: ${$height};
  min-width: ${$minWidth};
  max-width: ${$maxWidth};
  min-height: ${$minHeight};
  max-height: ${$maxHeight};
`;

const buildMarginCSS = ({ $margin, $mx, $my, $mt, $mb, $ml, $mr }: MarginProps) => css`
  margin: ${resolveThemeValue($margin)};
  margin: ${$mx && !$my && css`0 ${resolveThemeValue($mx)}`};
  margin: ${$my &&
  !$mx &&
  css`
    ${resolveThemeValue($my)} 0
  `};
  margin: ${$my &&
  $mx &&
  css`
    ${resolveThemeValue($my)} ${resolveThemeValue($mx)}
  `};
  margin-top: ${resolveThemeValue($mt)};
  margin-bottom: ${resolveThemeValue($mb)};
  margin-left: ${resolveThemeValue($ml)};
  margin-right: ${resolveThemeValue($mr)};
`;

const buildPaddingCSS = ({ $padding, $px, $py, $pt, $pb, $pl, $pr }: PaddingProps) => css`
  padding: ${resolveThemeValue($padding)};
  padding: ${$px && !$py && css`0 ${resolveThemeValue($px)}`};
  padding: ${$py &&
  !$px &&
  css`
    ${resolveThemeValue($py)} 0
  `};
  padding: ${$py &&
  $px &&
  css`
    ${resolveThemeValue($py)} ${resolveThemeValue($px)}
  `};
  padding-top: ${resolveThemeValue($pt)};
  padding-bottom: ${resolveThemeValue($pb)};
  padding-left: ${resolveThemeValue($pl)};
  padding-right: ${resolveThemeValue($pr)};
`;

export const border = css<BorderPropsResponsive>`
  ${(props) => buildBorderCSS(props)}
  ${mediaMdMin} {
    ${({ $mdMin = {} }) => buildBorderCSS($mdMin)}
  }

  ${mediaMdMax} {
    ${({ $mdMax = {} }) => buildBorderCSS($mdMax)}
  }

  ${mediaLgMin} {
    ${({ $lgMin = {} }) => buildBorderCSS($lgMin)}
  }

  ${mediaSmMax} {
    ${({ $smMax = {} }) => buildBorderCSS($smMax)}
  }
`;

export const sizing = css<SizingPropsResponsive>`
  ${(props) => buildSizingCSS(props)}

  ${mediaSmMin} {
    ${({ $smMin = {} }) => buildSizingCSS($smMin)}
  }

  ${mediaMdMin} {
    ${({ $mdMin = {} }) => buildSizingCSS($mdMin)}
  }

  ${mediaLgMin} {
    ${({ $lgMin = {} }) => buildSizingCSS($lgMin)}
  }

  ${mediaMdMax} {
    ${({ $mdMax = {} }) => buildSizingCSS($mdMax)}
  }
`;

export const margin = css<MarginPropsResponsive>`
  ${(props) => buildMarginCSS(props)}

  ${mediaSmMin} {
    ${({ $smMin = {} }) => buildMarginCSS($smMin)}
  }

  ${mediaMdMin} {
    ${({ $mdMin = {} }) => buildMarginCSS($mdMin)}
  }

  ${mediaLgMin} {
    ${({ $lgMin = {} }) => buildMarginCSS($lgMin)}
  }

  ${mediaSmMax} {
    ${({ $smMax = {} }) => buildMarginCSS($smMax)}
  }

  ${mediaMdMax} {
    ${({ $mdMax = {} }) => buildMarginCSS($mdMax)}
  }
`;

export const padding = css<PaddingPropsResponsive>`
  ${(props) => buildPaddingCSS(props)}

  ${mediaSmMin} {
    ${({ $smMin = {} }) => buildPaddingCSS($smMin)}
  }

  ${mediaMdMin} {
    ${({ $mdMin = {} }) => buildPaddingCSS($mdMin)}
  }

  ${mediaLgMin} {
    ${({ $lgMin = {} }) => buildPaddingCSS($lgMin)}
  }

  ${mediaSmMax} {
    ${({ $smMax = {} }) => buildPaddingCSS($smMax)}
  }

  ${mediaMdMax} {
    ${({ $mdMax = {} }) => buildPaddingCSS($mdMax)}
  }
`;
