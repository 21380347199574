import React from 'react';
import { CheckIcon, Loading, PrimaryBTN, PrimaryInactiveWrapper } from './shared';
import { PrimaryButtonIntProps } from './types';

export default function DeleteButton({
  loading = false,
  children,
  onClick,
  type,
  form = undefined,
  size = 'md',
  inactive = false,
  showCheckIcon = false,
  smallerPadding = false,
  dataTestId,
}: PrimaryButtonIntProps) {
  const button = (
    <PrimaryBTN
      className={`
      isDeleteButton
      ${loading ? 'loading' : ''}
      ${size === 'sm' ? 'btnSm' : 'btnMd'}
      ${smallerPadding ? 'smallerPadding' : ''}
      `}
      type={type}
      onClick={onClick}
      form={form}
      data-testid={dataTestId ?? 'delete-button'}
    >
      {loading ? (
        <Loading>
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </Loading>
      ) : (
        <>
          {showCheckIcon && <CheckIcon className="icon-icon-action-check" />}
          {children}
        </>
      )}
    </PrimaryBTN>
  );
  return inactive ? (
    <PrimaryInactiveWrapper className={`${inactive ? 'inactive' : ''}`}>{button}</PrimaryInactiveWrapper>
  ) : (
    <>{button}</>
  );
}
