import React from 'react';
import { CheckIcon, Loading, PrimaryBTN, PrimaryInactiveWrapper } from './shared';
import { PrimaryButtonIntProps } from './types';

export default function PrimaryButton({
  btnId,
  loading = false,
  disabled = false,
  children,
  onClick,
  type,
  form = undefined,
  size = 'md',
  inactive = false,
  className = '',
  showCheckIcon = false,
  smallerPadding = false,
  dataTestId,
  showDownloadIcon = false,
}: PrimaryButtonIntProps) {
  const button = (
    <PrimaryBTN
      className={`
        ${loading ? 'loading' : ''}
        ${size === 'sm' ? 'btnSm' : 'btnMd'}
        ${smallerPadding ? 'smallerPadding' : ''} ${className}`}
      type={type}
      disabled={loading || disabled}
      onClick={onClick}
      form={form}
      id={btnId}
      data-testid={dataTestId || `primary-button-${btnId || ''}`}
    >
      {loading ? (
        <Loading>
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </Loading>
      ) : (
        <>
          {showCheckIcon && <CheckIcon className="icon-icon-action-check" />}
          {showDownloadIcon && <CheckIcon className="icon-icon-action-download" />}

          {children}
        </>
      )}
    </PrimaryBTN>
  );

  return inactive ? (
    <PrimaryInactiveWrapper
      className={`
        ${inactive ? 'inactive' : ''}
        `}
    >
      {button}
    </PrimaryInactiveWrapper>
  ) : (
    button
  );
}
