import styled, { css } from 'styled-components';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';

export const Loading = styled.div`
  width: 70px;
  text-align: center;

  & > div {
    width: 12px;
    height: 12px;
    background-color: #1f2244;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

export const PrimaryBTN = styled.button`
  color: ${resolveThemeValue(tokens.color.text.textButton, 'var(--color-white)')};
  background-color: ${resolveThemeValue(tokens.color.buttons.buttonPrimary, 'var(--color-green)')};
  position: relative;
  cursor: pointer;

  border-radius: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.btnMd {
    font-size: 16px;
    line-height: 1;
    padding: 16px 28px;
  }

  &.btnSm {
    padding: 10px 28px;
    font-size: 14px;
    line-height: 16px;
  }

  ${resolveInCodebase(
    'fwc',
    css`
      border: none;
    `
  )}

  &:hover {
    background-color: ${resolveThemeValue(tokens.color.buttons.buttonHover, 'var(--color-dark-blue)')};
    color: ${resolveThemeValue(tokens.color.text.textButton, 'var(--color-beige)')};
  }

  &:focus-visible {
    background-color: #363856;
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed !important;
    background-color: var(--color-grey);
    color: var(--color-dark-grey);
    .isFWC & {
      color: ${resolveThemeValue(tokens.color.text.textDisabled)};
      background: ${resolveThemeValue(tokens.color.buttons.buttonDisabled)};
    }
  }

  &.disabledPayment {
    background: #9c8c8c;
    pointer-events: none;
    cursor: not-allowed !important;
    color: var(--color-dark-grey);
  }

  &.loading {
    background-color: ${resolveThemeValue(tokens.color.buttons.buttonDisabled, 'var(--color-grey)')};
    pointer-events: none;
    cursor: not-allowed !important;
  }

  &.isDeleteButton {
    background-color: var(--color-white);
    border: 1px solid ${resolveThemeValue(tokens.color.border.border, 'var(--color-error-red)')};
    color: ${resolveThemeValue(tokens.color.text.textError, 'var(--color-error-red)')};

    .isFWC & {
      margin: 1px 0;
    }

    .isFWC &:hover {
      margin: -1px 0;
      border: 2px solid ${resolveThemeValue(tokens.color.text.textError, 'var(--color-error-red)')};
    }
  }

  &.isOrangeGiftButton {
    background-color: var(--color-orange);
    border: none;
  }

  &.isErrorButton {
    background-color: var(--color-error-red);
    color: var(--color-white);
  }

  &.isWhiteVersion {
    border: solid 1px var(--color-beige);
    background-color: var(--color-white);
    color: var(--color-error-red);
  }

  &.isNearlyWhiteVersion {
    background-color: #edeae3;
    border: solid 1px #edeae3;
    color: #1f2244;
  }

  &.isSecondaryButton {
    color: ${resolveThemeValue(tokens.color.text.textPrimary, 'var(--color-dark-blue)')};
    background-color: ${resolveThemeValue(tokens.color.buttons.buttonSecondary, 'var(--color-beige)')};
    ${resolveInCodebase(
      'fwc',
      css`
        &.btnMd:not(.smallerPadding) {
          padding: 16px 28px;
        }
        &.btnSm {
          padding: 11px 29px;
        }
        border: 1px solid ${resolveThemeValue(tokens.color.border.border)};
      `
    )}

    &:hover {
      background: #f4f2f2;

      ${resolveInCodebase(
        'fwc',
        css`
          &.btnMd:not(.smallerPadding) {
            padding: 15px 27px;
          }
          &.btnMd.smallerPadding {
            padding: 9px 28px; !important;
          }

          &.btnSm {
            padding: 10px 28px !important;
          }
          background: white;
          border: 2px solid ${resolveThemeValue(tokens.color.border.borderHover)};
        `
      )}
    }
  }

  &.smallerPadding {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.stripeButton {
    background: #6772e5;
  }
`;

export const CheckIcon = styled.i`
  margin-right: 8px;

  &:before {
    color: white;
  }
`;

export const PrimaryInactiveWrapper = styled.div`
  &.inactive {
    cursor: not-allowed;
    width: 100%;

    button {
      background: #9c8c8c;
      color: #edeae3;
    }

    * {
      pointer-events: none;
    }
  }
`;
