/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

import PrimaryButton from 'ui/components/buttons/PrimaryButton';
import SecondaryButton from 'ui/components/buttons/SecondaryButton';
import DeleteButton from 'ui/components/buttons/DeleteButton';
import { PrimaryButtonIntProps } from 'ui/components/buttons/types';
import { CheckIcon, Loading, PrimaryBTN, PrimaryInactiveWrapper } from 'ui/components/buttons/shared';

const StripeButton = ({
  btnId,
  loading = false,
  children,
  onClick,
  type,
  form = undefined,
  size = 'md',
  inactive = false,
  showCheckIcon = false,
  smallerPadding = false,
  dataTestId,
}: PrimaryButtonIntProps) => {
  const button = (
    <PrimaryBTN
      className={`
        stripeButton
        ${loading ? 'loading' : ''}
        ${size === 'sm' ? 'btnSm' : 'btnMd'}
        ${smallerPadding ? 'smallerPadding' : ''}`}
      type={type}
      onClick={onClick}
      form={form}
      id={btnId}
      data-testid={dataTestId || `primary-button-${btnId || ''}`}
    >
      <>
        {loading ? (
          <Loading>
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </Loading>
        ) : (
          <>
            <StripeImg src="/static/images/stripe/stripe_icon.png" width={20} height={20} />
            {children}
          </>
        )}
      </>
    </PrimaryBTN>
  );

  return inactive ? (
    <PrimaryInactiveWrapper
      className={`
        ${inactive ? 'inactive' : ''}
        `}
    >
      {button}
    </PrimaryInactiveWrapper>
  ) : (
    <>{button}</>
  );
};

interface PrimaryButtonInt {
  children?: any;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: any;
  form?: any;
  inactive?: boolean;
  showCheckIcon?: boolean;
  size?: 'sm' | 'md';
  smallerPadding?: boolean;
  dataTestId?: string;
}

interface OrangeGiftButtonProps {
  children?: any;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: any;
  form?: any;
  inactive?: boolean;
  showCheckIcon?: boolean;
  size?: 'sm' | 'md';
  smallerPadding?: boolean;
}

const OrangeGiftButton = ({
  loading = false,
  children,
  onClick,
  type,
  form = undefined,
  size = 'md',
  inactive = false,
  showCheckIcon = false,
  smallerPadding = false,
}: OrangeGiftButtonProps) => {
  const button = (
    <PrimaryBTN
      className={`
      isOrangeGiftButton
    ${loading ? 'loading' : ''}
    ${size === 'sm' ? 'btnSm' : 'btnMd'}
    ${smallerPadding ? 'smallerPadding' : ''}
    `}
      type={type}
      onClick={onClick}
      form={form}
    >
      <>
        {loading ? (
          <Loading>
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </Loading>
        ) : (
          <>
            {showCheckIcon && <CheckIcon className="icon-icon-action-check" />}
            {children}
          </>
        )}
      </>
    </PrimaryBTN>
  );

  return inactive ? (
    <PrimaryInactiveWrapper className={`${inactive ? 'inactive' : ''}`}>{button}</PrimaryInactiveWrapper>
  ) : (
    <>{button}</>
  );
};

interface PrimaryButtonIntError {
  children?: any;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: any;
  form?: any;
  inactive?: boolean;
  showCheckIcon?: boolean;
  size?: 'sm' | 'md';
  smallerPadding?: boolean;
}

const ErrorButton = ({
  loading = false,
  children,
  onClick,
  type,
  form = undefined,
  size = 'md',
  inactive = false,
  showCheckIcon = false,
  smallerPadding = false,
}: PrimaryButtonIntError) => {
  const button = (
    <PrimaryBTN
      className={`
      isErrorButton
      ${loading ? 'loading' : ''}
      ${size === 'sm' ? 'btnSm' : 'btnMd'}
      ${smallerPadding ? 'smallerPadding' : ''}
      `}
      type={type}
      onClick={onClick}
      form={form}
    >
      <>
        {loading ? (
          <Loading>
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </Loading>
        ) : (
          <>
            {showCheckIcon && <CheckIcon className="icon-icon-action-check" />}
            {children}
          </>
        )}
      </>
    </PrimaryBTN>
  );

  return inactive ? (
    <PrimaryInactiveWrapper className={`${inactive ? 'inactive' : ''}`}>{button}</PrimaryInactiveWrapper>
  ) : (
    <>{button}</>
  );
};

interface WhiteButtonInt {
  children?: any;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: any;
  form?: any;
  inactive?: boolean;
  showCheckIcon?: boolean;
  size?: 'sm' | 'md';
  smallerPadding?: boolean;
  classNames?: string;
}

const WhiteButton = ({
  loading = false,
  children,
  onClick,
  type,
  form = undefined,
  size = 'md',
  inactive = false,
  showCheckIcon = false,
  smallerPadding = false,
  classNames = '',
}: WhiteButtonInt) => {
  const button = (
    <PrimaryBTN
      className={`
        isWhiteVersion
        ${loading ? 'loading' : ''}
        ${size === 'sm' ? 'btnSm' : 'btnMd'}
        ${smallerPadding ? 'smallerPadding' : ''}
        ${classNames}
        `}
      type={type}
      onClick={onClick}
      form={form}
    >
      <>
        {loading ? (
          <Loading>
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </Loading>
        ) : (
          <>
            {showCheckIcon && <CheckIcon className="icon-icon-action-check" />}
            {children}
          </>
        )}
      </>
    </PrimaryBTN>
  );

  return inactive ? (
    <PrimaryInactiveWrapper className={`${inactive ? 'inactive' : ''}`}>{button}</PrimaryInactiveWrapper>
  ) : (
    <>{button}</>
  );
};

interface NearlyWhiteButtonIntProps {
  children?: any;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: any;
  form?: any;
  inactive?: boolean;
  showCheckIcon?: boolean;
  size?: 'sm' | 'md';
  smallerPadding?: boolean;
}

const NearlyWhiteButtonInt = ({
  loading = false,
  children,
  onClick,
  type,
  form = undefined,
  size = 'md',
  inactive = false,
  showCheckIcon = false,
  smallerPadding = false,
}: NearlyWhiteButtonIntProps) => {
  const button = (
    <PrimaryBTN
      className={`
        isNearlyWhiteVersion
        ${loading ? 'loading' : ''}
        ${size === 'sm' ? 'btnSm' : 'btnMd'}
        ${smallerPadding ? 'smallerPadding' : ''}
        `}
      type={type}
      onClick={onClick}
      form={form}
    >
      <>
        {loading ? (
          <Loading>
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </Loading>
        ) : (
          <>
            {showCheckIcon && <CheckIcon className="icon-icon-action-check" />}
            {children}
          </>
        )}
      </>
    </PrimaryBTN>
  );

  return inactive ? (
    <PrimaryInactiveWrapper className={`${inactive ? 'inactive' : ''}`}>{button}</PrimaryInactiveWrapper>
  ) : (
    <>{button}</>
  );
};

const OrangeButton = styled.button`
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
  cursor: pointer;
`;

const StripeImg = styled.img`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

export {
  PrimaryButton,
  SecondaryButton,
  DeleteButton,
  WhiteButton,
  OrangeButton,
  Loading,
  OrangeGiftButton,
  ErrorButton,
  NearlyWhiteButtonInt,
  StripeButton,
};
