import React, { ComponentProps, ReactNode } from 'react';
import { CheckIcon, Loading, PrimaryBTN, PrimaryInactiveWrapper } from './shared';

interface SecondaryButtonInt {
  children?: any;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: any;
  iconStart?: ReactNode;
  form?: any;
  inactive?: boolean;
  showCheckIcon?: boolean;
  size?: 'sm' | 'md';
  smallerPadding?: boolean;
  dataTestId?: string;
  className?: string;
  disabled?: boolean;
  as?: ComponentProps<typeof PrimaryBTN>['as'];
}

export default function SecondaryButton({
  loading = false,
  children,
  iconStart,
  onClick,
  type,
  form = undefined,
  disabled = false,
  size = 'md',
  inactive = false,
  showCheckIcon = false,
  smallerPadding = false,
  dataTestId,
  className = '',
  as,
}: SecondaryButtonInt) {
  const button = (
    <PrimaryBTN
      disabled={disabled}
      className={`
         isSecondaryButton
         ${loading ? 'loading' : ''}
         ${size === 'sm' ? 'btnSm' : 'btnMd'}
         ${smallerPadding ? 'smallerPadding' : ''}
         ${className}
    `}
      type={type}
      onClick={onClick}
      form={form}
      data-testid={dataTestId ?? 'secondary-button'}
      as={as}
    >
      {iconStart}
      {loading ? (
        <Loading>
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </Loading>
      ) : (
        <>
          {showCheckIcon && <CheckIcon className="icon-icon-action-check" />}
          {children}
        </>
      )}
    </PrimaryBTN>
  );

  return inactive ? (
    <PrimaryInactiveWrapper className={`${inactive ? 'inactive' : ''}`}>{button}</PrimaryInactiveWrapper>
  ) : (
    button
  );
}
