'use client';

import styled, { css } from 'styled-components';
import { Property } from 'csstype';
import { mediaLgMin, mediaMdMax, mediaMdMin, mediaSmMax, mediaSmMin } from '../utils/breakpoints';
import {
  border,
  BorderProps,
  ColorString,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  sizing,
  SizingProps,
} from './mixins';
import { NestedThemePaths, resolveThemeValue } from '../design/utils';

export type Offset =
  | [top: string, left: string]
  | {
      left?: string;
      top?: string;
      right?: string;
      bottom?: string;
    };

export interface BoxProps extends PaddingProps, MarginProps, SizingProps, BorderProps {
  $flex?: Property.Flex;
  $flexDirection?: Property.FlexDirection;
  $flexFlow?: Property.FlexFlow;
  $order?: Property.Order;
  $overflow?: Property.Overflow;
  $position?: Property.Position;
  $display?: Property.Display;
  $cursor?: Property.Cursor;
  $boxShadow?: Property.BoxShadow;
  $opacity?: Property.Opacity;
  $tDecoration?: Property.TextDecoration;
  $hideIfEmpty?: boolean;
  $background?: ColorString;
  $zIndex?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 100 | 1_000 | 10_000;
  $offset?: Offset;
}

export interface BoxPropsResponsive extends BoxProps {
  $mdMin?: BoxProps;
  $mdMax?: BoxProps;
  $lgMin?: BoxProps;
  $smMax?: BoxProps;
  $smMin?: BoxProps;
}

export const Box = styled.div<BoxPropsResponsive>`
  ${padding}
  ${margin}
  ${sizing}
  ${border}

  ${(props) => buildBoxCSS(props)};

  ${mediaMdMin} {
    ${({ $mdMin = {} }) => buildBoxCSS($mdMin)}
  }

  ${mediaSmMin} {
    ${({ $smMin = {} }) => buildBoxCSS($smMin)}
  }

  ${mediaSmMax} {
    ${({ $smMax = {} }) => buildBoxCSS($smMax)}
  }

  ${mediaLgMin} {
    ${({ $lgMin = {} }) => buildBoxCSS($lgMin)}
  }

  ${mediaMdMax} {
    ${({ $mdMax = {} }) => buildBoxCSS($mdMax)}
  }
`;

const buildBoxCSS = ({
  $flex,
  $flexDirection,
  $flexFlow,
  $order,
  $display,
  $cursor,
  $background,
  $zIndex,
  $position,
  $overflow,
  $boxShadow,
  $opacity,
  $hideIfEmpty,
  $tDecoration,
  $offset,
}: BoxProps) => css`
  flex: ${$flex};
  flex-direction: ${$flexDirection};
  flex-flow: ${$flexFlow};
  order: ${$order};
  display: ${$display ? `${$display} !important` : undefined};
  cursor: ${$cursor};
  background: ${resolveThemeValue($background)};
  z-index: ${$zIndex};
  overflow: ${$overflow};
  box-shadow: ${$boxShadow};
  position: ${$position};
  opacity: ${$opacity};
  text-decoration: ${$tDecoration};
  ${$hideIfEmpty &&
  css`
    &:empty {
      display: none;
    }
  `} ${$offset && getOffset($offset)}
`;

const getOffset = (offset: Offset) => {
  if (typeof offset === 'object') {
    return css`
      ${'left' in offset && `left: ${offset.left};`}
      ${'right' in offset && `right: ${offset.right};`}
      ${'top' in offset && `top: ${offset.top};`}
      ${'bottom' in offset && `bottom: ${offset.bottom};`}
    `;
  }
  return css`
    left: ${offset[0]};
    right: ${offset[1]};
  `;
};
